<template>
  <div>
    <van-nav-bar
      v-if="comp1 != 7"
      :title="titleName"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div>
      <!-- <update-user></update-user> -->
      <template v-if="comp1 == 0">
        <component :is="UpdateUser"></component>
      </template>
      <template v-if="comp1 == 1">
        <component :is="CollectionIndex"></component>
      </template>

      <template v-if="comp1 == 2">
        <component :is="HisIndex"></component>
      </template>
      <template v-if="comp1 == 3">
        <component :is="MyMessage"></component>
      </template>
      <template v-if="comp1 == 4">
        <component :is="ContMe"></component>
      </template>
      <template v-if="comp1 == 5">
        <component :is="Statute"></component>
      </template>
      <template v-if="comp1 == 7">
        <component :is="MyRelease"></component>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
import UpdateUser from "@/components/setUser/UpdateUser.vue";
import HisIndex from "@/components/setUser/HisIndex.vue";
import CollectionIndex from "@/components/setUser/CollectionIndex.vue";
import ContMe from "@/components/setUser/ContMe.vue";
import MyMessage from "@/components/setUser/MyMessage.vue";
import Statute from "@/components/setUser/Statute.vue";
import MyRelease from "@/views/MyRelease.vue";
import {
  onBeforeMount,
  onBeforeUnmount,
  onBeforeUpdate,
  onMounted,
  onUnmounted,
  onUpdated,
  ref,
} from "vue";
const onClickLeft = () => history.back();
let comp1 = ref(0);
let titleName = ref("");

onMounted(() => {
  comp1.value = route.params.index;
  if (comp1.value == 0) {
    titleName.value = "个人信息设置";
  }
  if (comp1.value == 1) {
    titleName.value = "我的收藏";
  }
  if (comp1.value == 2) {
    titleName.value = "历史足迹";
  }
  if (comp1.value == 3) {
    titleName.value = "我的消息";
  }
  if (comp1.value == 4) {
    titleName.value = "联系我们";
  }
  if (comp1.value == 5) {
    titleName.value = "法律声明";
  }
  if (comp1.value == 7) {
    titleName.value = "我的发布";
  }
});


</script>

<style>
</style>