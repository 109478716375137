<template>
  <div>
    <nav>
      <router-link to="/"></router-link>
    </nav>
    <router-view />
  </div>
</template>
<script>
window.onload = function () {
  document.addEventListener("touchstart", function (e) {
    if (e.touches.length > 1) {
      e.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (e) {
    e.preventDefault();
  });
};
</script>

<style>
@font-face {
  font-family: myFont;
  src: url("./assets/思源黑体/SourceHanSansCN-Normal.otf");
}
*,
body {
  padding: 0;
  margin: 0;
  font-family: myFont;
}
</style>
