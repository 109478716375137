<!-- 首页 -->
<template>
  <div>
    <div
      v-if="selectBool"
      style="
        width: 100%;
        height: calc(100% - 100px);
        padding: 2px 0px;
        background-color: white;
        position: absolute;
        left: 0;
        bottom: 0px;
        z-index: 1000;
        overflow-y: scroll;
      "
      @click="onblur"
    >
      <p
        v-show="tabbool"
        style="font-size: 14px; color: rgb(192, 201, 207); padding: 8px 20px"
      >
        最近搜索记录
      </p>
      <div @click.stop v-if="tabbool" style="width: 95%; margin: 0 auto">
        <el-tag
          v-for="tag in tags"
          :key="tag.name"
          closable
          :type="tag.type"
          size="large"
          style="margin: 6px 8px"
          @close="handleClose(tag)"
          @click="textBtn(tag)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
      <div v-else>
        <el-empty v-if="selectData.length == 0" :image-size="200"></el-empty>
        <div v-else @click.stop style="width: 93%; margin: 10px auto 0 auto">
          <van-card
            :title="item.title"
            @click="nextToMsg(item.businessId, item.businessType)"
            v-for="(item, index) in selectData"
            :key="index"
            style="height: 128px"
          >
            <template #thumb>
              <img :src="item.fileUrl" style="width: 40vw; height: 110px" />
            </template>
            <template #tags>
              <!-- 最多显示三行 -->
              <div
                class="van-multi-ellipsis--l3"
                style="margin-bottom: 6px; margin-top: 6px;"
              >
                {{ item.content }}
              </div>
              <div>
                <span
                  v-for="(item1, index1) in Department"
                  :key="index1"
                  style="
                    margin-right: 5px;
                    font-size: 12px;
                    color: rgb(152, 152, 152);
                  "
                  v-show="item.departmentType == JSON.stringify(index1)"
                  >{{ item1 }}</span
                >
                <span
                  style="
                    margin-right: 5px;
                    font-size: 12px;
                    color: rgb(143, 195, 32);
                  "
                  v-if="item.type == 1"
                  >图文</span
                >
                <span
                  style="
                    margin-right: 5px;
                    font-size: 12px;
                    color: rgb(143, 195, 32);
                  "
                  v-if="item.type == 2"
                  >视频</span
                >
                <span
                  style="
                    margin-right: 5px;
                    font-size: 12px;
                    color: rgb(143, 195, 32);
                  "
                  v-if="item.type == 3"
                  >文本</span
                >

                <span
                  style="
                    margin-right: 5px;
                    font-size: 12px;
                    color: rgb(143, 195, 32);
                  "
                  v-if="item.businessType == 1"
                  >案例</span
                >
                <span
                  style="
                    margin-right: 5px;
                    font-size: 12px;
                    color: rgb(143, 195, 32);
                  "
                  v-if="item.businessType == 2"
                  >贴吧</span
                >
                <span
                  style="
                    margin-right: 5px;
                    font-size: 12px;
                    color: rgb(143, 195, 32);
                  "
                  v-if="item.businessType == 3"
                  >文献</span
                >
                <span
                  style="
                    margin-right: 5px;
                    font-size: 12px;
                    color: rgb(143, 195, 32);
                  "
                  v-if="item.businessType == 4"
                  >直播</span
                >
              </div>
            </template>
            <!-- <template #footer>
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <div style="padding-top: 7px; color: rgb(192, 196, 204)">
                  <span>浏览量：{{ item.viewCount }}</span>
                  <span style="padding-left: 10px"
                    >作者：{{ item.createBy }}</span
                  >
                </div>
              </div>
            </template> -->
          </van-card>
        </div>
      </div>
    </div>
    <!-- 顶部 -->
    <div
      style="width: 100%; background-color: rgb(242, 242, 242); height: 70vh"
    >
      <div
        v-if="!selectBool"
        style="
          width: 100%;
          height: 14vh;
          background: linear-gradient(
            to right,
            rgb(223, 236, 156),
            rgb(130, 198, 159)
          );
          transition: height 0.4s ease-in;
        "
        ref="scrDiv"
      >
        <transition name="fade">
          <div
            style="
              width: 100%;
              height: 6vh;
              display: flex;
              justify-content: space-between;
              padding-top: 6px;
              align-items: center;
            "
            v-show="scrollBool"
          >
            <div>
              <img
                src="../assets/logo1.png"
                style="width: 110px; margin-left: 20px"
              />
            </div>
            <div
              style="
                font-size: 18px;
                color: white;
                padding-right: 4%;
                transform: scale(1);
              "
            >
              科技创新<span>&nbsp;&nbsp;</span> 守望生命
            </div>
          </div>
        </transition>
        <div style="width: 83%; position: relative">
          <form action="/">
            <van-search
              v-model="selvalue"
              placeholder="请输入搜索关键词"
              shape="round"
              @search="onSearch"
              @cancel="onCancel"
              @focus="onfocus"
            />
          </form>
          <div
            style="
              position: absolute;
              top: 50%;
              right: -16%;
              transform: translateY(-50%);
            "
          >
            <van-icon
              name="underway-o"
              size="23"
              @click="onClickRight(2)"
              style="margin: 0px 5px"
              color="rgb(255,255,255)"
            >
            </van-icon>
            <van-icon
              name="star-o"
              color="rgb(255,255,255)"
              size="23"
              @click="onClickRight(1)"
              style="padding-left: 1px"
            ></van-icon>
          </div>
        </div>
      </div>

      <van-nav-bar
        v-if="selectBool"
        title="搜索"
        @click-left="onClickLeft"
        left-text="返回"
        left-arrow
      >
      </van-nav-bar>
      <div v-if="selectBool" style="width: 85%; position: relative">
        <form action="/">
          <van-search
            v-model="selvalue"
            placeholder="请输入搜索关键词"
            shape="round"
            @search="onSearch"
            @cancel="onCancel"
            @focus="onfocus"
          />
        </form>
        <div
          style="
            position: absolute;
            top: 50%;
            right: -13%;
            transform: translateY(-50%);
          "
        >
          <van-icon
            name="underway-o"
            size="20"
            @click="onClickRight(2)"
            style="margin: 0px 5px"
          >
          </van-icon>
          <van-icon name="star-o" size="20" @click="onClickRight(1)"></van-icon>
        </div>
      </div>
      <!-- 悬浮 -->
      <van-floating-bubble
        v-model:offset="offset1"
        style="
          overflow: visible;
          background: linear-gradient(
            to right,
            rgb(130, 200, 160),
            rgb(223, 236, 156)
          );
          width: 55px;
          height: 55px;
        "
        v-if="bubbleshow1"
      >
        <div>
          <van-icon
            name="chat-o"
            style="padding-left: 13px"
            size="21"
            @click="onBubbleClick(1)"
          />
          <div style="font-size: 12px; transform: scale(0.8)">联系我们</div>
        </div>
        <div class="delbtn floatColor" @click.stop>
          <van-icon name="clear" size="17" @click="delBubb(1)"></van-icon></div
      ></van-floating-bubble>
      <van-floating-bubble
        v-model:offset="offset2"
        style="
          overflow: visible;
          background: linear-gradient(
            to right,
            rgb(130, 200, 160),
            rgb(223, 236, 156)
          );
          width: 55px;
          height: 55px;
        "
        v-if="bubbleshow2"
      >
        <div>
          <van-icon
            name="user-o"
            style="padding-left: 12px"
            size="22"
            @click="onBubbleClick(2)"
          />
          <div style="font-size: 12px; transform: scale(0.8)">关于我们</div>
        </div>
        <div class="delbtn floatColor" @click.stop>
          <van-icon name="clear" size="17" @click="delBubb(2)"></van-icon></div
      ></van-floating-bubble>
      <!-- 主页-轮播 -->
      <div
        v-if="!selectBool"
        style="width: 100%; height: calc(100vh - 20vh); overflow-y: scroll"
        v-loading="loadBool"
        @scroll="scrollChange"
        ref="scrDiv1"
      >
        <div style="width: 100%; background-color: rgb(255, 255, 255)">
          <div
            style="
              width: 94%;
              margin: 0 auto;
              border-radius: 5px;
              overflow: hidden;
              padding: 10px 0 15px 0;
            "
          >
            <van-swipe
              :autoplay="3000"
              lazy-render
              indicator-color="rgb(142,194,31)"
              style="border-radius: 5px"
            >
              <van-swipe-item
                v-for="(image, index) in images"
                :key="image.imgUrl"
              >
                <img
                  :src="image.imgUrl"
                  @click="navigBtn(image.url)"
                  style="width: 100%; height: 22vh"
                />
              </van-swipe-item>
            </van-swipe>
          </div>
          <!-- <div
            style="
              width: 95%;
              height: 80px;
              margin: 10px auto 10px auto;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              padding-top: 7px;
              background-color: rgb(236, 239, 247);
            "
          >
            <div class="rad_box">
              <img
                src="../assets/直播.png"
                class="imgbox"
                @click="modleNext(1)"
              />
              <p style="font-size: 13px">精品直播</p>
            </div>
            <div class="rad_box">
              <img
                src="../assets/新人课程.png"
                class="imgbox"
                @click="modleNext(2)"
              />
              <p style="font-size: 13px">课程案例</p>
            </div>
            <div class="rad_box">
              <img
                src="../assets/重点医疗器械.png"
                class="imgbox"
                @click="modleNext(3)"
              />
              <p style="font-size: 13px">器械讲解</p>
            </div>
            <div class="rad_box">
              <img
                src="../assets/人物.png"
                class="imgbox"
                @click="modleNext(4)"
              />
              <p style="font-size: 13px">风云人物</p>
            </div>
          </div> -->
          <div
            style="
              width: 100%;
              height: 5px;
              background-color: rgb(242, 242, 242);
            "
          ></div>
          <div style="width: 100%; background-color: rgb(255, 255, 255)">
            <!-- 推荐案例 -->
            <div
              style="
                height: 40px;
                width: 100%;
                display: flex;
                align-items: center;
                position: relative;
              "
            >
              <div
                style="
                  color: rgb(102, 102, 102);
                  font-size: 15px;
                  padding-top: 30px;
                  padding-left: 20px;
                  font-weight: bold;
                "
              >
                精品推荐
              </div>
              <div
                class="last_text"
                @click="contentBtn(1)"
                v-show="forListBool"
              >
                更多内容 <van-icon name="arrow" />
              </div>
              <div
                class="last_text"
                @click="contentBtn(2)"
                v-show="!forListBool"
              >
                收起<van-icon name="arrow-left" />
              </div>
            </div>
            <div style="width: 93%; margin: 0px auto 0 auto">
              <van-swipe
                class="my-swipe"
                :autoplay="3000"
                indicator-color="rgb(142,194,31)"
              >
                <van-swipe-item>
                  <van-row
                    :gutter="[10, 10]"
                    style="width: 100%; margin: 20px auto 35px auto"
                  >
                    <van-col
                      span="12"
                      v-for="(item, index) in forList0"
                      :key="index"
                    >
                      <van-grid
                        :column-num="1"
                        @click="toMSg(item)"
                        style="
                          border-radius: 5px;
                          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                          overflow: hidden;
                          padding: 6px;
                        "
                      >
                        <img
                          :src="imgUrl0[index]"
                          style="width: 100%; height: 120px; object-fit: cover"
                          v-if="imgUrl0[index] != 1"
                        />
                        <img
                          :src="require('@/assets/文件.png')"
                          style="width: 66%; height: 120px; margin: 0 auto"
                          v-else
                        />
                        <div
                          style="
                            width: 100%;
                            padding-left: 5px;
                            background-color: white;
                          "
                        >
                          <van-text-ellipsis
                            :content="item.title"
                            style="
                              font-size: 12px;
                              color: rgb(102, 102, 102);
                              padding-top: 4px;
                              font-weight: bold;
                            "
                          />
                          <div style="padding-bottom: 5px">
                            <span
                              v-for="(item1, index1) in Department"
                              :key="index1"
                              style="
                                margin-right: 10px;
                                font-size: 12px;
                                color: rgb(152, 152, 152);
                              "
                              v-show="
                                item.departmentType == JSON.stringify(index1)
                              "
                              >{{ item1 }}</span
                            >
                            <span
                              style="
                                margin-right: 5px;
                                font-size: 12px;
                                color: rgb(143, 195, 32);
                              "
                              v-if="item.businessType == 1"
                              >案例</span
                            >
                            <span
                              style="
                                margin-right: 5px;
                                font-size: 12px;
                                color: rgb(143, 195, 32);
                              "
                              v-if="item.businessType == 2"
                              >贴吧</span
                            >
                          </div>
                        </div>
                      </van-grid>
                    </van-col>
                  </van-row>
                </van-swipe-item>
                <van-swipe-item>
                  <van-row
                    :gutter="[10, 10]"
                    style="width: 100%; margin: 20px auto 0 auto"
                  >
                    <van-col
                      span="12"
                      v-for="(item, index) in forList1"
                      :key="index"
                    >
                      <van-grid
                        :column-num="1"
                        @click="toMSg(item)"
                        style="
                          border-radius: 5px;
                          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                          overflow: hidden;
                          padding: 6px;
                        "
                      >
                        <img
                          :src="imgUrl1[index]"
                          style="width: 100%; height: 120px; object-fit: cover"
                          v-if="imgUrl1[index] != 1"
                        />
                        <img
                          :src="require('@/assets/文件.png')"
                          style="width: 66%; height: 120px; margin: 0 auto"
                          v-else
                        />
                        <div
                          style="
                            width: 100%;
                            padding-left: 5px;
                            background-color: white;
                          "
                        >
                          <van-text-ellipsis
                            :content="item.title"
                            style="
                              font-size: 14px;
                              color: rgb(102, 102, 102);
                              padding-top: 4px;
                            "
                          />
                          <div style="padding-bottom: 5px">
                            <span
                              v-for="(item1, index1) in Department"
                              :key="index1"
                              style="
                                margin-right: 10px;
                                font-size: 12px;
                                color: rgb(152, 152, 152);
                              "
                              v-show="
                                item.departmentType == JSON.stringify(index1)
                              "
                              >{{ item1 }}</span
                            >
                            <span
                              style="
                                margin-right: 5px;
                                font-size: 12px;
                                color: rgb(143, 195, 32);
                              "
                              v-if="item.businessType == 1"
                              >案例</span
                            >
                            <span
                              style="
                                margin-right: 5px;
                                font-size: 12px;
                                color: rgb(143, 195, 32);
                              "
                              v-if="item.businessType == 2"
                              >贴吧</span
                            >
                          </div>
                        </div>
                      </van-grid>
                    </van-col>
                  </van-row>
                </van-swipe-item>
                <van-swipe-item>
                  <van-row
                    :gutter="[10, 10]"
                    style="width: 100%; margin: 20px auto 0 auto"
                  >
                    <van-col
                      span="12"
                      v-for="(item, index) in forList2"
                      :key="index"
                    >
                      <van-grid
                        :column-num="1"
                        @click="toMSg(item)"
                        style="
                          border-radius: 5px;
                          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                          overflow: hidden;
                          padding: 6px;
                        "
                      >
                        <img
                          :src="imgUrl2[index]"
                          style="width: 100%; height: 120px; object-fit: cover"
                          v-if="imgUrl2[index] != 1"
                        />
                        <img
                          :src="require('@/assets/文件.png')"
                          style="width: 66%; height: 120px; margin: 0 auto"
                          v-else
                        />
                        <div
                          style="
                            width: 100%;
                            padding-left: 5px;
                            background-color: white;
                          "
                        >
                          <van-text-ellipsis
                            :content="item.title"
                            style="
                              font-size: 14px;
                              color: rgb(102, 102, 102);
                              padding-top: 4px;
                            "
                          />
                          <div style="padding-bottom: 5px">
                            <span
                              v-for="(item1, index1) in Department"
                              :key="index1"
                              style="
                                margin-right: 10px;
                                font-size: 12px;
                                color: rgb(152, 152, 152);
                              "
                              v-show="
                                item.departmentType == JSON.stringify(index1)
                              "
                              >{{ item1 }}</span
                            >
                            <span
                              style="
                                margin-right: 5px;
                                font-size: 12px;
                                color: rgb(143, 195, 32);
                              "
                              v-if="item.businessType == 1"
                              >案例</span
                            >
                            <span
                              style="
                                margin-right: 5px;
                                font-size: 12px;
                                color: rgb(143, 195, 32);
                              "
                              v-if="item.businessType == 2"
                              >贴吧</span
                            >
                          </div>
                        </div>
                      </van-grid>
                    </van-col>
                  </van-row>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>
          <div style="width: 100%; background-color: rgb(255, 255, 255)">
            <!-- 推荐直播 -->
            <div
              style="
                height: 40px;
                width: 100%;
                display: flex;
                align-items: center;
                position: relative;
              "
            >
              <!-- <img
                src="../assets/推荐火苗.png"
                style="width: 20px; height: 20px; padding-right: 3px"
              /> -->
              <div
                style="
                  color: rgb(102, 102, 102);
                  font-size: 15px;
                  padding-left: 20px;
                  font-weight: bold;
                "
              >
                直播推荐
              </div>
              <div
                class="last_text1"
                @click="contentBtn2(1)"
                v-show="videoBool"
              >
                更多内容 <van-icon name="arrow" />
              </div>
              <div
                class="last_text1"
                @click="contentBtn2(2)"
                v-show="!videoBool"
              >
                收起<van-icon name="arrow-left" />
              </div>
            </div>
            <van-row
              :gutter="[20, 20]"
              style="width: 93%; margin: 10px auto 0 auto"
            >
              <van-col span="24">
                <van-card
                  :title="item.roomName"
                  :thumb="item.coverUrl"
                  v-for="(item, index) in videoList"
                  :key="index"
                  @click="videoMsg(item)"
                  style="
                    border-radius: 5px;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                    overflow: hidden;
                    padding: 10px;
                    background-color: white;
                    height: 128px;
                    margin-bottom: 8px;
                  "
                >
                  <template #thumb>
                    <img
                      :src="item.coverUrl"
                      style="width: 45vw; height: 110px"
                    />
                  </template>
                  <template #tags>
                    <div style="padding-top: 6px">
                      <span
                        v-for="(item1, index1) in Department"
                        :key="index1"
                        style="
                          margin-right: 10px;
                          font-size: 12px;
                          color: rgb(152, 152, 152);
                        "
                        v-show="item.type == JSON.stringify(index1)"
                        >{{ item1 }}</span
                      >
                      <span
                        v-show="item.status == '1'"
                        style="
                          margin-right: 5px;
                          font-size: 12px;
                          color: rgb(143, 195, 32);
                        "
                        >直播中</span
                      >
                      <span
                        v-show="item.status == '0'"
                        style="
                          margin-right: 5px;
                          font-size: 12px;
                          color: rgb(143, 195, 32);
                        "
                        >未开始</span
                      >
                      <span
                        v-show="item.status == '2'"
                        style="
                          margin-right: 5px;
                          font-size: 12px;
                          color: rgb(143, 195, 32);
                        "
                        >已结束</span
                      >
                    </div>
                    <div
                      style="
                        color: rgb(192, 196, 204);
                        float: left;
                        padding-top: 5px;
                        transform: scale(0.9);
                        transform-origin: top left;
                      "
                    >
                      <div style="padding-bottom: 6px">
                        观看人数：{{ item.viewCount }}
                      </div>
                      <div style="padding-bottom: 6px">
                        <van-icon name="underway-o" /> {{ item.startTime }}
                      </div>
                      <div style="padding-bottom: 6px">
                        <van-icon name="underway" /> {{ item.endTime }}
                      </div>
                    </div>
                  </template>
                  <template #footer> </template>
                </van-card>
              </van-col>
            </van-row>
          </div>
        </div>
      </div>
    </div>
    <home-view :active="0"></home-view>
  </div>
</template>
<script setup lang="ts">
import HomeView from "./HomeView.vue";
import { showToast } from "vant";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import {
  swImage,
  contentList,
  selectHisList,
  delHisList,
  getHisList,
} from "@/api/main";
import { videoRoomList } from "@/api/video";
import { showDialog } from "vant";
import { tr } from "element-plus/es/locale";
const selvalue = ref("");

const onCancel = () => showToast("取消");
const images = ref([]);
const forList = ref([]);
const forList0 = ref([]);
const forList1 = ref([]);
const forList2 = ref([]);
const forLists = ref([]);
const videoLists = ref([]);
const videoList = ref([]);
const imgUrl = ref([]);
const imgUrl0 = ref([]);
const imgUrl1 = ref([]);
const imgUrl2 = ref([]);
const loadBool = ref(true);
const forListBool = ref(true);
const videoBool = ref(true);
const tabbool = ref(true);
const Department = ref([
  "胸外科",
  "肝胆外科",
  "神经外科",
  "妇科",
  "泌尿外科",
  "骨科",
  "小儿外科",
]);
const tags = ref([
  { name: "标签四", type: "warning" },
  { name: "标签四", type: "warning" },
  { name: "标签四", type: "warning" },
  { name: "标签四", type: "warning" },
  { name: "标签四", type: "warning" },
  { name: "标签四", type: "warning" },
  { name: "标签四", type: "warning" },
]);
const offset1 = ref({ x: "100vw", y: 470 });
const offset2 = ref({ x: "100vw", y: 530 });
const bubbleshow1 = ref(true);
const bubbleshow2 = ref(true);
const selectBool = ref(false);
const selectData = ref([]);
const scrDiv = ref();
const scrDiv1 = ref();
const scrollBool = ref(true);
onMounted(async () => {
  bubbleshow1.value =
    localStorage.getItem("bubbleshow1").toLowerCase() == "true";
  bubbleshow2.value =
    localStorage.getItem("bubbleshow2").toLowerCase() == "true";
  //轮播图
  let res1 = await swImage();
  if (res1.data.code == 200) {
    res1.data.rows.forEach((item) => {
      images.value.push({
        imgUrl: item.fileUrl,
        url: item.url,
      });
    });
  }
  //推荐
  let res2 = await contentList();
  if (res2.data.code == 200) {
    forLists.value = res2.data.rows;
    forList.value = forLists.value.slice(0, 4);
    forList0.value = forLists.value.slice(0, 2);
    forList1.value = forLists.value.slice(2, 4);
    forList2.value = forLists.value.slice(4, 6);

    forList.value.forEach((item) => {
      if (item.type == 2) {
        imgUrl.value.push(item.videoTitle);
      } else if (item.type == 1 && item.filesList != null) {
        imgUrl.value.push(item.filesList[0].url);
      } else if (item.type == 1 && item.filesList == null) {
        imgUrl.value.push("");
      } else if (item.type == 3) {
        imgUrl.value.push(1);
      } else {
        imgUrl.value.push("");
      }
    });
    //
    forList0.value.forEach((item) => {
      if (item.type == 2) {
        imgUrl0.value.push(item.videoTitle);
      } else if (item.type == 1 && item.filesList != null) {
        imgUrl0.value.push(item.filesList[0].url);
      } else if (item.type == 1 && item.filesList == null) {
        imgUrl0.value.push("");
      } else if (item.type == 3) {
        imgUrl0.value.push(1);
      } else {
        imgUrl0.value.push("");
      }
    });
    forList1.value.forEach((item) => {
      if (item.type == 2) {
        imgUrl1.value.push(item.videoTitle);
      } else if (item.type == 1 && item.filesList != null) {
        imgUrl1.value.push(item.filesList[0].url);
      } else if (item.type == 1 && item.filesList == null) {
        imgUrl1.value.push("");
      } else if (item.type == 3) {
        imgUrl1.value.push(1);
      } else {
        imgUrl1.value.push("");
      }
    });
    forList2.value.forEach((item) => {
      if (item.type == 2) {
        imgUrl2.value.push(item.videoTitle);
      } else if (item.type == 1 && item.filesList != null) {
        imgUrl2.value.push(item.filesList[0].url);
      } else if (item.type == 1 && item.filesList == null) {
        imgUrl2.value.push("");
      } else if (item.type == 3) {
        imgUrl2.value.push(1);
      } else {
        imgUrl2.value.push("");
      }
    });
  }
  //直播
  let res3 = await videoRoomList();
  if (res3.data.code == 200) {
    console.log(res3.data);
    videoLists.value = res3.data.rows;
    videoList.value = videoLists.value.slice(0, 2);
    loadBool.value = false;
  }
  loadBool.value = false;
});
const active = ref(0);
//案例详情
const toMSg = (val: any) => {
  console.log(val);
  let value: any = "";
  let index: any = "";
  if (val.businessType == "1") {
    index = 0;
  } else {
    index = 1;
  }
  value = val.businessId;
  router.push({
    path: "/TiebMsg",
    query: {
      value: value,
      index: index,
    },
  });
};
//直播详情
const videoMsg = (val: any) => {
  router.push({
    path: "/VideoMsg",
    query: {
      value: JSON.stringify(val),
    },
  });
};
//其他模块
const modleNext = (val: number) => {
  router.push({
    path: "/OtherModule",
  });
};
const contentBtn = (val: any) => {
  if (val == 1) {
    router.push("/ForumIndex");
    // forList.value = forLists.value;
    // forListBool.value = false;
    // imgUrl.value = [];
    // forList.value.forEach((item) => {
    //   if (item.type == 2) {
    //     imgUrl.value.push(item.videoTitle);
    //   } else if (item.type == 1 && item.filesList != null) {
    //     imgUrl.value.push(item.filesList[0].url);
    //   } else if (item.type == 1 && item.filesList == null) {
    //     imgUrl.value.push("");
    //   } else if (item.type == 3) {
    //     imgUrl.value.push(1);
    //   } else {
    //     imgUrl.value.push("");
    //   }
    // });
  }
  if (val == 2) {
    imgUrl.value = [];
    forList.value = forLists.value.slice(0, 4);
    forListBool.value = true;
    forList.value.forEach((item) => {
      if (item.type == 2) {
        imgUrl.value.push(item.videoTitle);
      } else if (item.type == 1 && item.filesList != null) {
        imgUrl.value.push(item.filesList[0].url);
      } else if (item.type == 1 && item.filesList == null) {
        imgUrl.value.push("");
      } else if (item.type == 3) {
        imgUrl.value.push(1);
      } else {
        imgUrl.value.push("");
      }
    });
  }
};
const contentBtn2 = (val: any) => {
  if (val == 1) {
    // videoList.value = videoLists.value;
    // videoBool.value = false;
    router.push("/VideoIndex");
  }
  if (val == 2) {
    videoList.value = videoLists.value.slice(0, 2);
    videoBool.value = true;
  }
};
const onClickRight = (val: Number) => {
  if (val == 2) {
    router.push({
      path: "/Setting/2",
    });
  } else {
    router.push({
      path: "/Setting/1",
    });
  }
};
const onBubbleClick = (val: Number) => {
  if (val == 1) {
    showDialog({
      title: "联系方式",
      message: "电话：+86 757 - 8677 0960\n邮箱：info@optomedic.com",
    }).then(() => {
      // on close
    });
  } else {
    router.push({
      path: "/Setting/5",
    });
  }
};
const delBubb = (val: Number) => {
  if (val == 1) {
    bubbleshow1.value = false;
    localStorage.setItem("bubbleshow1", "false");
  } else {
    bubbleshow2.value = false;
    localStorage.setItem("bubbleshow2", "false");
  }
};
//搜索
const onSearch = (val: any) => {
  let data = {
    content: val,
  };
  getHisList(data).then((res) => {
    console.log(res);
    selectData.value = res.data.rows;
    tabbool.value = false;
    // showToast("搜索成功");
  });
};
//搜索框获取焦点
const onfocus = () => {
  selectHisList({}).then((res) => {
    console.log(res);
    if (res.data.code == 200) {
      tags.value = [];
      res.data.rows.forEach((item: any) => {
        tags.value.push({
          name: item.title,
          type: "warning",
          value: item.historySearchLogId,
        });
      });
      selectBool.value = true;
      tabbool.value = true;
    }
  });
};
const onblur = () => {
  selectBool.value = false;
  // selvalue.value = "";
};
//删除搜索记录
const handleClose = (tag: any) => {
  delHisList(tag.value).then((res) => {
    tags.value.splice(tags.value.indexOf(tag), 1);
  });
};
const textBtn = (tag: any) => {
  console.log(tag);
  selvalue.value = tag.name;
};
const onClickLeft = () => {
  selectBool.value = false;
  selvalue.value = "";
};
const nextToMsg = (val, index) => {
  router.push({
    path: "/TiebMsg",
    query: {
      value: val,
      index: index - 1,
    },
  });
};
const navigBtn = (url: any) => {
  window.open(url);
};
//滚动时隐藏logo
const scrollChange = (env: any) => {
  if (env.target.scrollTop >= 30) {
    scrollBool.value = false;
    scrDiv.value.style.height = 7 + "vh";
    scrDiv1.value.style.height = 'calc(100vh - 14vh)';
   
  } else {
    scrollBool.value = true;
    scrDiv.value.style.height = 14 + "vh";
    scrDiv1.value.style.height = 'calc(100vh - 20vh)';
   
  }
};
</script>

<style scoped>
/* ::v-deep .van-search__field {
  height: 48px;
} */
.floatColor {
  background: linear-gradient(to right, rgb(130, 200, 160), rgb(223, 236, 156));
}
::v-deep .van-cell {
  padding: 0 !important;
}
::v-deep .van-field__control {
  padding: 5px !important;
}
::v-deep .van-search {
  background-color: rgba(0, 0, 0, 0);
}
.van-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .van-search__action {
  background-color: rgb(25, 137, 250) !important;
}
::v-deep .van-card__content > div {
  padding-left: calc(100% - 42vw);
}

.parent-element {
  height: 100px; /* 或者使用其他值 */
  overflow: hidden; /* 避免超出父元素的内容被显示 */
}
.rad_box {
  width: 15%;
  height: 65%;
  background-color: rgb(160, 207, 255);
  border-radius: 50%;
  margin: 0 15px;
  text-align: center;
}
.imgbox {
  width: 70%;
  height: 70%;
  /* border-radius: 50%; */
  padding-left: 2px;
  padding-top: 6px;
  margin-bottom: 15px;
}
.delbtn {
  position: absolute;
  top: -6px;
  right: 0;
  border-radius: 50%;
  z-index: 1000;
  background-color: rgb(25, 137, 250);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.last_text {
  padding-top: 30px;
  font-size: 12px;
  color: rgb(102, 102, 102);
  position: absolute;
  right: 20px;
  font-weight: bold;
}
.last_text1 {
  /* padding-top: 30px; */
  font-size: 12px;
  color: rgb(102, 102, 102);
  position: absolute;
  right: 20px;
  font-weight: bold;
}
.my-swipe .van-swipe-item {
  /* color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed; */
}
.van-floating-bubble {
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}


</style>
