<!-- 直播首页 -->
<template>
  <div>
    <div style="width: 100%">
      <van-nav-bar
        title="直播"
        @click-left="onClickLeft"
        @click-right="onClickRight"
        style="margin-bottom: 10px"
      >
        <template #right>
          <van-icon name="plus" size="18" color="rgb(255,255,255)" /> </template
      ></van-nav-bar>
      <van-tabs
        v-model:active="active"
        type="card"
        active-color="#FF0000"
        title-inactive-color="rgb(141,141,141)"
      >
        <van-tab title="全部">
          <div style="width: 100%; height: 10px"></div>
          <van-tabs
            v-model:active="active1"
            type="card"
            active-color="#FF0000"
            title-inactive-color="rgb(141,141,141)"
          >
            <van-tab title="直播中">
              <van-pull-refresh
                v-model="loading"
                @refresh="onRefresh"
                :disabled="freshBool"
              >
                <div class="msgbox" @scroll="handleScroll" v-loading="loadBool">
                  <van-card
                    :title="item.roomName"
                    :thumb="item.coverUrl"
                    @click="nextToMsg(item)"
                    v-for="(item, index) in videoData"
                    :key="index"
                  >
                    <template #thumb>
                      <img
                        :src="item.coverUrl"
                        style="width: 38vw; height: 118px"
                      />
                    </template>
                    <template #tags>
                      <div style="padding-top: 6px">
                        <span
                          v-for="(item1, index1) in Department"
                          :key="index1"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(152, 152, 152);
                          "
                          v-show="item.type == JSON.stringify(index1)"
                          >{{ item1 }}</span
                        >
                        <span
                          v-show="item.status == '1'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >直播中</span
                        >
                        <span
                          v-show="item.status == '0'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >未开始</span
                        >
                        <span
                          v-show="item.status == '2'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >已结束</span
                        >
                      </div>
                      <div
                        style="
                          color: rgb(192, 196, 204);
                          float: left;
                          padding-top: 5px;
                        "
                      >
                        <div style="padding-bottom: 6px">
                          观看人数：{{ item.viewCount }}
                        </div>
                        <div style="padding-bottom: 6px">
                          <van-icon name="underway-o" /> {{ item.startTime }}
                        </div>
                        <div style="padding-bottom: 6px">
                          <van-icon name="underway" /> {{ item.endTime }}
                        </div>
                      </div>
                    </template>
                  </van-card>
                </div></van-pull-refresh
              >
            </van-tab>
            <van-tab title="未开始">
              <van-pull-refresh
                v-model="loading"
                @refresh="onRefresh"
                :disabled="freshBool"
              >
                <div class="msgbox" @scroll="handleScroll" v-loading="loadBool">
                  <van-card
                    :title="item.roomName"
                    :thumb="item.coverUrl"
                    @click="nextToMsg(item)"
                    v-for="(item, index) in videoData"
                    :key="index"
                  >
                    <template #thumb>
                      <img
                        :src="item.coverUrl"
                        style="width: 38vw; height: 118px"
                      />
                    </template>
                    <template #tags>
                      <div style="padding-top: 6px">
                        <span
                          v-for="(item1, index1) in Department"
                          :key="index1"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(152, 152, 152);
                          "
                          v-show="item.type == JSON.stringify(index1)"
                          >{{ item1 }}</span
                        >
                        <span
                          v-show="item.status == '1'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >直播中</span
                        >
                        <span
                          v-show="item.status == '0'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >未开始</span
                        >
                        <span
                          v-show="item.status == '2'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >已结束</span
                        >
                      </div>
                      <div class="timetext">
                        <div style="padding-bottom: 6px">
                          观看人数：{{ item.viewCount }}
                        </div>
                        <div style="padding-bottom: 6px">
                          <van-icon name="underway-o" /> {{ item.startTime }}
                        </div>
                        <div style="padding-bottom: 6px">
                          <van-icon name="underway" /> {{ item.endTime }}
                        </div>
                      </div>
                    </template>
                  </van-card>
                </div></van-pull-refresh
              >
            </van-tab>
            <van-tab title="已结束">
              <van-pull-refresh
                v-model="loading"
                @refresh="onRefresh"
                :disabled="freshBool"
              >
                <div class="msgbox" @scroll="handleScroll" v-loading="loadBool">
                  <van-card
                    :title="item.roomName"
                    :thumb="item.coverUrl"
                    @click="nextToMsg(item)"
                    v-for="(item, index) in videoData"
                    :key="index"
                  >
                    <template #thumb>
                      <img
                        :src="item.coverUrl"
                        style="width: 38vw; height: 118px"
                      />
                    </template>
                    <template #tags>
                      <div style="padding-top: 6px">
                        <span
                          v-for="(item1, index1) in Department"
                          :key="index1"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(152, 152, 152);
                          "
                          v-show="item.type == JSON.stringify(index1)"
                          >{{ item1 }}</span
                        >
                        <span
                          v-show="item.status == '1'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >直播中</span
                        >
                        <span
                          v-show="item.status == '0'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >未开始</span
                        >
                        <span
                          v-show="item.status == '2'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >已结束</span
                        >
                      </div>
                      <div class="timetext">
                        <div style="padding-bottom: 6px">
                          观看人数：{{ item.viewCount }}
                        </div>
                        <div style="padding-bottom: 6px">
                          <van-icon name="underway-o" /> {{ item.startTime }}
                        </div>
                        <div style="padding-bottom: 6px">
                          <van-icon name="underway" /> {{ item.endTime }}
                        </div>
                      </div>
                    </template>
                  </van-card>
                </div></van-pull-refresh
              >
            </van-tab>
          </van-tabs>
        </van-tab>
        <van-tab
          :title="item0"
          v-for="(item0, index0) in Department"
          :key="index0"
        >
          <div style="width: 100%; height: 10px"></div>
          <van-tabs
            v-model:active="active1"
            type="card"
            active-color="#FF0000"
            title-inactive-color="rgb(141,141,141)"
          >
            <van-tab title="直播中">
              <van-pull-refresh
                v-model="loading"
                @refresh="onRefresh"
                :disabled="freshBool"
              >
                <div class="msgbox" @scroll="handleScroll" v-loading="loadBool">
                  <van-card
                    :title="item.roomName"
                    :thumb="item.coverUrl"
                    @click="nextToMsg(item)"
                    v-for="(item, index) in videoData"
                    :key="index"
                  >
                    <template #thumb>
                      <img
                        :src="item.coverUrl"
                        style="width: 38vw; height: 118px"
                      />
                    </template>
                    <template #tags>
                      <div style="padding-top: 6px">
                        <span
                          v-for="(item1, index1) in Department"
                          :key="index1"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(152, 152, 152);
                          "
                          v-show="item.type == JSON.stringify(index1)"
                          >{{ item1 }}</span
                        >
                        <span
                          v-show="item.status == '1'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >直播中</span
                        >
                        <span
                          v-show="item.status == '0'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >未开始</span
                        >
                        <span
                          v-show="item.status == '2'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >已结束</span
                        >
                      </div>
                      <div class="timetext">
                        <div style="padding-bottom: 6px">
                          观看人数：{{ item.viewCount }}
                        </div>
                        <div style="padding-bottom: 6px">
                          <van-icon name="underway-o" /> {{ item.startTime }}
                        </div>
                        <div style="padding-bottom: 6px">
                          <van-icon name="underway" /> {{ item.endTime }}
                        </div>
                      </div>
                    </template>
                  </van-card>
                </div></van-pull-refresh
              >
            </van-tab>
            <van-tab title="未开始">
              <van-pull-refresh
                v-model="loading"
                @refresh="onRefresh"
                :disabled="freshBool"
              >
                <div class="msgbox" @scroll="handleScroll" v-loading="loadBool">
                  <van-card
                    :title="item.roomName"
                    :thumb="item.coverUrl"
                    @click="nextToMsg(item)"
                    v-for="(item, index) in videoData"
                    :key="index"
                  >
                    <template #thumb>
                      <img
                        :src="item.coverUrl"
                        style="width: 38vw; height: 118px"
                      />
                    </template>
                    <template #tags>
                      <div style="padding-top: 6px">
                        <span
                          v-for="(item1, index1) in Department"
                          :key="index1"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(152, 152, 152);
                          "
                          v-show="item.type == JSON.stringify(index1)"
                          >{{ item1 }}</span
                        >
                        <span
                          v-show="item.status == '1'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >直播中</span
                        >
                        <span
                          v-show="item.status == '0'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >未开始</span
                        >
                        <span
                          v-show="item.status == '2'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >已结束</span
                        >
                      </div>
                      <div class="timetext">
                        <div style="padding-bottom: 6px">
                          观看人数：{{ item.viewCount }}
                        </div>
                        <div style="padding-bottom: 6px">
                          <van-icon name="underway-o" /> {{ item.startTime }}
                        </div>
                        <div style="padding-bottom: 6px">
                          <van-icon name="underway" /> {{ item.endTime }}
                        </div>
                      </div>
                    </template>
                  </van-card>
                </div></van-pull-refresh
              >
            </van-tab>
            <van-tab title="已结束">
              <van-pull-refresh
                v-model="loading"
                @refresh="onRefresh"
                :disabled="freshBool"
              >
                <div class="msgbox" @scroll="handleScroll" v-loading="loadBool">
                  <van-card
                    :title="item.roomName"
                    :thumb="item.coverUrl"
                    @click="nextToMsg(item)"
                    v-for="(item, index) in videoData"
                    :key="index"
                  >
                    <template #thumb>
                      <img
                        :src="item.coverUrl"
                        style="width: 38vw; height: 118px"
                      />
                    </template>
                    <template #tags>
                      <div style="padding-top: 6px">
                        <span
                          v-for="(item1, index1) in Department"
                          :key="index1"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(152, 152, 152);
                          "
                          v-show="item.type == JSON.stringify(index1)"
                          >{{ item1 }}</span
                        >
                        <span
                          v-show="item.status == '1'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >直播中</span
                        >
                        <span
                          v-show="item.status == '0'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >未开始</span
                        >
                        <span
                          v-show="item.status == '2'"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          >已结束</span
                        >
                      </div>
                      <div class="timetext">
                        <div style="padding-bottom: 6px">
                          观看人数：{{ item.viewCount }}
                        </div>
                        <div style="padding-bottom: 6px">
                          <van-icon name="underway-o" /> {{ item.startTime }}
                        </div>
                        <div style="padding-bottom: 6px">
                          <van-icon name="underway" /> {{ item.endTime }}
                        </div>
                      </div>
                    </template>
                  </van-card>
                </div></van-pull-refresh
              >
            </van-tab>
          </van-tabs>
        </van-tab>
      </van-tabs>
    </div>
    <home-view :active="1"></home-view>
  </div>
</template>
  <script setup lang="ts">
import HomeView from "./HomeView.vue";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import {
  forumList,
  caseList,
  collLikeApi,
  cancelCollLikeApi,
} from "@/api/forum";
import { videoRoomList } from "@/api/video";
const router = useRouter();
import { showToast } from "vant";
import { any } from "video.js/dist/types/utils/events";
const active = ref(0);
const active1 = ref(2);
const freshBool = ref(false);
const videoData = ref([]);
const Department = ref([
  "胸外科",
  "肝胆外科",
  "神经外科",
  "妇科",
  "泌尿外科",
  "骨科",
  "小儿外科",
]);
const onClickLeft = () => history.back();
const loading = ref(false);
const detailsBool = ref(true);
const favorBtncolor = ref("");
const likeBtncolor = ref("");
const loadBool = ref(true);
const dataValue = ref({
  status: 0,
  type: "",
});
const status0 = ref("");
const type0 = ref("");

watch(active, (val) => {
  loadBool.value = true;
  if (val == 0) {
    type0.value = "";
  } else {
    type0.value = val - 1;
  }
  dataValue.value = {
    status: status0.value,
    type: type0.value,
  };
  videoRoomList(dataValue.value).then((res) => {
    if (res.data.code == 200) {
      videoData.value = res.data.rows;
      loadBool.value = false;
    }
  });
});
watch(active1, (val) => {
  sessionStorage.setItem("videoStatus", val);
  if (val == 0) {
    status0.value = 1;
  }
  if (val == 1) {
    status0.value = 0;
  }
  if (val == 2) {
    status0.value = 2;
  }
  dataValue.value = {
    status: status0.value,
    type: type0.value,
  };
  videoData.value = [];
  videoRoomList(dataValue.value).then((res) => {
    if (res.data.code == 200) {
      videoData.value = res.data.rows;
      loadBool.value = false;
    }
  });
});
onMounted(() => {
  let status = sessionStorage.getItem("videoStatus");
  console.log("f", status);
  if (status == null || status == 0) {
    active1.value = 0;
    status0.value = 1;
  } else if (status == 1) {
    active1.value = 1;
    status0.value = 0;
  } else {
    active1.value = 2;
    status0.value = 2;
  }
  dataValue.value = {
    status: status0.value,
    type: type0.value,
  };
  console.log("df0", dataValue.value);

  getvideoList();
});
const onClickRight = () => {
  router.push({
    path: "/ReleaseVideo",
    query: {
      id: "1",
    },
  });
};
//直播列表
const getvideoList = () => {
  loadBool.value = true;
  videoRoomList(dataValue.value).then((res) => {
    if (res.data.code == 200) {
      videoData.value = res.data.rows;
      loadBool.value = false;
    }
  });
};

//刷新
const onRefresh = () => {
  videoRoomList(dataValue.value)
    .then((res) => {
      if (res.data.code == 200) {
        videoData.value = res.data.rows;
        showToast("刷新成功");
        loading.value = false;
      }
    })
    .catch((err) => {
      showToast("刷新失败");
      loading.value = false;
    });
};

//滚动条触顶
const handleScroll = (event: any) => {
  const scrollTop = event.target.scrollTop;
  if (scrollTop == 0) {
    freshBool.value = false;
  } else {
    freshBool.value = true;
  }
};
//进入详情
const nextToMsg = (value) => {
  let obj = JSON.stringify(value);
  router.push({
    path: "/VideoMsg",
    query: {
      value: obj,
    },
  });
};
</script>
<style scoped>
.msgbox {
  width: 96%;
  height: calc(100vh - 183px);
  overflow-y: scroll;
  margin: 10px auto 0 auto;
  padding: 5px;
}
::v-deep .van-card__content > div {
  padding-left: calc(100% - 47vw);
}
.timetext {
  color: rgb(192, 196, 204);
  float: left;
  padding-top: 5px;
  transform: scale(0.9);
  transform-origin: top left;
}
</style>
<style>
.van-button__text {
  padding-top: 2px;
}
.van-card__thumb {
  width: 100px !important;
  height: 100px !important;
}
.van-card__title {
  font-size: 16px;
  /* padding-bottom: 6px; */
}
.van-nav-bar__title {
  color: white !important;
}
.van-nav-bar__content {
  background: linear-gradient(to right, rgb(223, 236, 156), rgb(130, 198, 159));
}
.van-nav-bar__text {
  color: white !important;
}
.van-tab--card.van-tab--active {
  /* background: linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(223, 236, 156)
  ) !important; */
  background-color: rgb(177, 217, 157) !important;
}
.van-tabs__nav--card {
  border: none !important;
  background-color: rgb(248, 248, 248) !important;
  border-radius: 50px !important;
  padding: 0 10px !important;
}

.van-tab--card {
  border: none !important;
  border-radius: 50px !important;
}
.van-card {
  background-color: white !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  /* padding: 5px !important; */
}
.van-card__thumb img {
  object-fit: scale-down !important;
}
.van-card__title {
  color: rgb(96, 96, 96) !important;
}
.van-picker__confirm {
  color: rgb(130, 198, 159) !important;
}

.van-radio__icon--checked .van-icon {
  background-color: rgb(130, 198, 159) !important;
  border-color: rgb(130, 198, 159) !important;
}
</style>