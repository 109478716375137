<template>
  <div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="username"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field name="radio" label="性别">
          <template #input>
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
              <van-radio name="3">其他</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="phonenumber"
          name="手机"
          label="手机"
          placeholder="手机号"
          :rules="[{ required: true, message: '请填写用户名' }]"
          type="number"
          maxlength="11"
        />
        <van-field
          v-model="occupation"
          name="职业"
          label="职业"
          maxlength="10"
          placeholder="职业"
        />
        <van-field
          readonly
          v-model="createTime"
          name="注册时间"
          label="注册时间"
        />

        <!-- <van-field
          v-model="username"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]"
        /> -->
        <!-- <van-field
          v-model="oldpassword"
          type="oldpassword"
          name="原密码"
          label="原密码"
          placeholder="原密码"
          :rules="[{ required: true, message: '请填写原密码' }]"
        />
        <van-field
          v-model="password"
          type="password"
          name="新密码"
          label="新密码"
          placeholder="新密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
        <van-field
          v-model="newpassword"
          type="password"
          name="确认密码"
          label="确认密码"
          placeholder="新密码"
          :rules="[
            { required: true, message: '请再次输入密码' },
            { validator, message: '密码不一致' },
          ]"
        /> -->
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          修改
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script setup lang="ts">
import { showNotify } from 'vant';
import { onMounted, onUnmounted, ref } from "vue";
import { getUserMsg, editUser, updateToken, getList } from "@/api/user";
import { useRouter } from "vue-router";
const router = useRouter();
const username = ref("");
const password = ref("");
const oldpassword = ref("");
const newpassword = ref("");
const createTime = ref("");
const phonenumber = ref("");
const sex = ref("");
const userId = ref("");
const occupation = ref("");
const onSubmit = (values) => {
  console.log("submit", values);
  let data = {
    userName: values.用户名,
    phonenumber: values.手机,
    sex: values.radio,
    userId:userId.value
  };
  editUser(data).then((res) => {
    console.log(res);
    if (res.data.code == 200) {
      router.push("/UserIndex");
      localStorage.setItem("username", values.用户名);
      showNotify({ type: 'success', message: '修改成功', duration: 1000 });
    }
  });
};
const validator = () => {
  return password.value == newpassword.value;
};
onMounted(() => {
  localStorage.getItem("username");
  let data = {
    username: localStorage.getItem("username"),
  };
  getUserMsg(data).then((res) => {
    console.log(res);
    if (res.data.code == 200) {
      username.value = res.data.user.userName;
      createTime.value = res.data.user.createTime;
      phonenumber.value = res.data.user.phonenumber;
      sex.value = res.data.user.sex;
      userId.value = res.data.user.userId
    }
  });
});
</script>


<style>
</style>