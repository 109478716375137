<!-- 登录 -->
<template>
  <div
    style="
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(135, 139, 153);
    "
  >
    <div class="Loginbox">
      <p style="font-size: 24px; text-align: center; padding: 20px">登录</p>
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="username"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button
            :loading="logBool"
            round
            block
            type="primary"
            native-type="submit"
          >
            登 录
          </van-button>
        </div>
      </van-form>
      <div
        style="
          width: 100%;
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
        "
      >
        <p
          style="padding-left: 50px; color: rgb(227, 61, 48)"
          @click="resetBtn"
        >
          忘记密码？
        </p>
        <p
          style="padding-right: 50px; color: rgb(45, 158, 75)"
          @click="createBtn"
        >
          注册用户
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { loginUser } from "@/api/user";
const username = ref("");
const password = ref("");
const router = useRouter();
let logBool = ref(false);

const onSubmit = (values:any) => {
  console.log("submit", values);
  let data = {
    username: values.用户名,
    password: values.密码,
  };
  logBool.value = true;
  // router.replace("/MainIndex");
  loginUser(data).then((res) => {
    if (res.data.code == 200) {
      logBool.value = false;
      localStorage.setItem("token", res.data.data.access_token);
      localStorage.setItem("username",values.用户名);
      localStorage.setItem('bubbleshow1','true')
      localStorage.setItem('bubbleshow2','true')
      router.replace("/MainIndex");
    }else{
      logBool.value = false;
    }
  }).catch(err=>{
    logBool.value = false;
  });
};
const createBtn = () => {
  router.push("/CreateUser");
};
const resetBtn = () => {
  router.push("/ResetUser");
};
</script>
<style>
.Loginbox {
  width: 90%;
  height: 350px;
  background: white;
  border-radius: 5px;
}
</style>
  