<!-- 案例首页 -->
<template>
  <div>
    <div style="width: 100%">
      <van-nav-bar
        title="论坛"
        @click-left="onClickLeft"
        @click-right="onClickRight"
      >
        <template #right> <van-icon name="plus" size="18" /> </template
      ></van-nav-bar>
      <div style="width: 100%; height: 10px"></div>
      <van-tabs
        v-model:active="active1"
        type="card"
        active-color="#FF0000"
        title-inactive-color="rgb(141,141,141)"
      >
        <van-tab
          :title="item0"
          v-for="(item0, index0) in Department0"
          :key="index0"
        >
          <div style="width: 100%; height: 10px"></div>
          <van-tabs
            v-model:active="active"
            type="card"
            active-color="#FF0000"
            title-inactive-color="rgb(141,141,141)"
          >
            <div style="width: 100%; height: 5px"></div>
            <van-tab title="案例">
              <van-pull-refresh
                v-model="loading"
                @refresh="onRefresh"
                :disabled="freshBool"
              >
                <div class="msgbox" @scroll="handleScroll" v-loading="loadBool">
                  <van-card
                    :title="item.title"
                    :thumb="
                      item.type == 2
                        ? item.videoTitle
                        : item.type == 1
                        ? item.filesList[0].url
                        : require('@/assets/文件.png')
                    "
                    @click="nextToMsg(item.caseId, 0)"
                    v-for="(item, index) in caseData"
                    :key="index"
                    style="height: 128px"
                  >
                    <template #thumb>
                      <img
                        :src="
                          item.type == 2
                            ? item.videoTitle
                            : item.type == 1
                            ? item.filesList[0].url
                            : require('@/assets/文件.png')
                        "
                        style="width: 40vw; height: 110px"
                      />
                    </template>
                    <template #tags>
                      <!-- 最多显示三行 -->
                      <!-- <div
                        class="van-multi-ellipsis--l3"
                        style="margin-bottom: 6px"
                      >
                        {{ item.content }}
                      </div> -->
                      <div style="padding-top: 6px">
                        <span
                          v-for="(item1, index1) in Department"
                          :key="index1"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(152, 152, 152);
                          "
                          v-show="item.departmentType == JSON.stringify(index1)"
                          >{{ item1 }}</span
                        >
                        <span
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          v-if="item.type == 1"
                          >图文</span
                        >
                        <span
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          v-if="item.type == 2"
                          >视频</span
                        >
                        <span
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          v-if="item.type == 3"
                          >病例文本</span
                        >
                        <span
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          v-if="item.type == 4"
                          >手术方案</span
                        >
                      </div>
                      <div class="msgtext">
                        <span>作者：{{ item.createBy }}</span>
                        <span style="padding-left: 10px"
                          >浏览量：{{ item.viewCount }}</span
                        >
                      </div>
                      <div style="padding-top: 6px">
                        <van-button
                          round
                          v-show="item.collectFlag == 'false'"
                          icon="star"
                          size="mini"
                          style="color: rgb(98, 98, 98); padding: 0 10px"
                          @click="FavoriteBtn(item.caseId, 1, index)"
                          >收藏 <span>{{ item.collectCount }}</span></van-button
                        >
                        <van-button
                          round
                          v-show="item.collectFlag != 'false'"
                          color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
                          icon="star"
                          size="mini"
                          style="padding: 0 10px"
                          @click="FavoriteBtn(item.caseId, 1, index)"
                          >已收藏
                          <span>{{ item.collectCount }}</span></van-button
                        >
                        <van-button
                          round
                          v-show="item.likeFlag == 'false'"
                          icon="like"
                          size="mini"
                          style="color: rgb(98, 98, 98); padding: 0 10px"
                          @click="LikeBtn(item.caseId, 1, index)"
                          >点赞(<span>{{ item.likeCount }}</span
                          >)</van-button
                        >
                        <van-button
                          round
                          v-show="item.likeFlag != 'false'"
                          color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
                          icon="like"
                          size="mini"
                          style="padding: 0 10px"
                          @click="LikeBtn(item.caseId, 1, index)"
                          >赞 <span>{{ item.likeCount }}</span></van-button
                        >
                      </div>
                    </template>
                  </van-card>
                </div>
                <div style="width: 100%; height: 55px"></div>
              </van-pull-refresh>
            </van-tab>
            <van-tab title="贴吧">
              <van-pull-refresh
                v-model="loading"
                @refresh="onRefresh"
                :disabled="freshBool"
              >
                <div class="msgbox" @scroll="handleScroll" v-loading="loadBool">
                  <van-card
                    :title="item.title"
                    :thumb="
                      item.type == 2
                        ? item.videoTitle
                        : item.type == 1
                        ? item.filesList[0].url
                        : require('@/assets/文件.png')
                    "
                    @click="nextToMsg(item.postBarId, 1)"
                    v-for="(item, index) in forumData"
                    :key="index"
                    style="height: 128px"
                  >
                    <template #thumb>
                      <img
                        :src="
                          item.type == 2
                            ? item.videoTitle
                            : item.type == 1
                            ? item.filesList[0].url
                            : require('@/assets/文件.png')
                        "
                        style="width: 40vw; height: 110px"
                      />
                    </template>
                    <template #tags>
                      <!-- 最多显示三行 -->
                      <!-- <div
                        class="van-multi-ellipsis--l3"
                        style="margin-bottom: 6px"
                      >
                        {{ item.content }}
                      </div> -->
                      <div style="padding-top: 6px">
                        <span
                          v-for="(item1, index1) in Department"
                          :key="index1"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(152, 152, 152);
                          "
                          v-show="item.departmentType == JSON.stringify(index1)"
                          >{{ item1 }}</span
                        >
                        <!-- <span
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          v-if="item.type == 1"
                          >案例</span
                        >
                        <span
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          v-if="item.type == 2"
                          >贴吧</span
                        >
                        <span
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          v-if="item.type == 3"
                          >文本</span
                        > -->
                        <span
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          v-if="item.type == 1"
                          >图文</span
                        >
                        <span
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          v-if="item.type == 2"
                          >视频</span
                        >
                      </div>
                      <div class="msgtext">
                        <span>作者：{{ item.createBy }}</span>
                        <span style="padding-left: 10px"
                          >浏览量：{{ item.viewCount }}</span
                        >
                      </div>
                      <div style="padding-top: 6px">
                        <van-button
                          round
                          v-show="item.collectFlag == 'false'"
                          icon="star"
                          size="mini"
                          style="color: rgb(98, 98, 98); padding: 0 10px"
                          @click="FavoriteBtn(item.postBarId, 2, index)"
                          >收藏 <span>{{ item.collectCount }}</span></van-button
                        >
                        <van-button
                          round
                          v-show="item.collectFlag != 'false'"
                          color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
                          icon="star"
                          size="mini"
                          style="padding: 0 10px"
                          @click="FavoriteBtn(item.postBarId, 2, index)"
                          >已收藏
                          <span>{{ item.collectCount }}</span></van-button
                        >
                        <van-button
                          round
                          v-show="item.likeFlag == 'false'"
                          icon="like"
                          size="mini"
                          style="color: rgb(98, 98, 98); padding: 0 10px"
                          @click="LikeBtn(item.postBarId, 2, index)"
                          >点赞(<span>{{ item.likeCount }}</span
                          >)</van-button
                        >
                        <van-button
                          round
                          v-show="item.likeFlag != 'false'"
                          color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
                          icon="like"
                          size="mini"
                          style="padding: 0 10px"
                          @click="LikeBtn(item.postBarId, 2, index)"
                          >赞 <span>{{ item.likeCount }}</span></van-button
                        >
                      </div>
                    </template>
                  </van-card>
                </div>
                <div style="width: 100%; height: 55px"></div>
              </van-pull-refresh>
            </van-tab>
            <van-tab title="文献">
              <van-pull-refresh
                v-model="loading"
                @refresh="onRefresh"
                :disabled="freshBool"
              >
                <div class="msgbox" @scroll="handleScroll" v-loading="loadBool">
                  <van-card
                    :title="item.title"
                    :thumb="item.literatureCoverPictureUrl"
                    @click="nextToMsg(item.literatureId, 2)"
                    v-for="(item, index) in wenData"
                    :key="index"
                    style="height: 128px"
                  >
                    <template #thumb>
                      <img
                        :src="
                          item.type == 2
                            ? item.videoTitle
                            : item.type == 1
                            ? item.literatureCoverPictureUrl
                            : item.literatureCoverPictureUrl
                        "
                        style="width: 40vw; height: 110px"
                      />
                    </template>
                    <template #tags>
                      <!-- 最多显示三行 -->
                      <!-- <div
                        class="van-multi-ellipsis--l3"
                        style="margin-bottom: 6px"
                      >
                        {{ item.content }}
                      </div> -->
                      <div style="padding-top: 6px">
                        <span
                          v-for="(item1, index1) in Department"
                          :key="index1"
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(152, 152, 152);
                          "
                          v-show="item.departmentType == JSON.stringify(index1)"
                          >{{ item1 }}</span
                        >
                        <span
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          v-if="item.type == 1"
                          >案例</span
                        >
                        <span
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          v-if="item.type == 2"
                          >贴吧</span
                        >
                        <span
                          style="
                            margin-right: 5px;
                            font-size: 12px;
                            color: rgb(143, 195, 32);
                          "
                          v-if="item.type == 3"
                          >文本</span
                        >
                      </div>
                      <div class="msgtext">
                        <span>作者：{{ item.createBy }}</span>
                        <span style="padding-left: 10px"
                          >浏览量：{{ item.viewCount }}</span
                        >
                      </div>
                      <div style="padding-top: 6px">
                        <van-button
                          v-show="item.collectFlag == 'false'"
                          icon="star"
                          size="mini"
                          round
                          style="color: rgb(98, 98, 98); padding: 0 10px"
                          @click="FavoriteBtn(item.literatureId, 3, index)"
                          >收藏 <span>{{ item.collectCount }}</span></van-button
                        >
                        <van-button
                          v-show="item.collectFlag != 'false'"
                          round
                          color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
                          icon="star"
                          size="mini"
                          style="padding: 0 10px"
                          @click="FavoriteBtn(item.literatureId, 3, index)"
                          >已收藏
                          <span>{{ item.collectCount }}</span></van-button
                        >
                        <van-button
                          round
                          v-show="item.likeFlag == 'false'"
                          icon="like"
                          size="mini"
                          style="color: rgb(98, 98, 98); padding: 0 10px"
                          @click="LikeBtn(item.literatureId, 3, index)"
                          >点赞(<span>{{ item.likeCount }}</span
                          >)</van-button
                        >
                        <van-button
                          round
                          v-show="item.likeFlag != 'false'"
                          color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
                          icon="like"
                          size="mini"
                          style="padding: 0 10px"
                          @click="LikeBtn(item.literatureId, 3, index)"
                          >赞 <span>{{ item.likeCount }}</span></van-button
                        >
                      </div>
                    </template>
                  </van-card>
                </div>
                <div style="width: 100%; height: 55px"></div>
              </van-pull-refresh>
            </van-tab>
          </van-tabs>
        </van-tab>
      </van-tabs>
    </div>
    <home-view :active="2"></home-view>
  </div>
</template>
  <script setup lang="ts">
import HomeView from "./HomeView.vue";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import {
  forumList,
  caseList,
  collLikeApi,
  cancelCollLikeApi,
  getwenList,
} from "@/api/forum";
const router = useRouter();
import { showToast } from "vant";
const active = ref(0);
const active1 = ref(0);
const freshBool = ref(false);
const caseData = ref([]);
const forumData = ref([]);
const wenData = ref([]);
const onClickLeft = () => history.back();
const loading = ref(false);
const detailsBool = ref(true);
const favorBtncolor = ref("");
const likeBtncolor = ref("");
const loadBool = ref(true);
const departmentType0 = ref("");
// "胸外科",
//   "肝胆外科",
//   "神经外科",
//   "妇科",
//   "泌尿外科",
//   "骨科",
//   "小儿外科",
const Department0 = ref([
  "全部",
  "胸外科",
  "肝胆外科",
  "神经外科",
  "妇科",
  "泌尿外科",
  "骨科",
  "小儿外科",
]);
const Department = ref([
  "胸外科",
  "肝胆外科",
  "神经外科",
  "妇科",
  "泌尿外科",
  "骨科",
  "小儿外科",
]);
watch(active1, (newValue, oldValue) => {
  console.log(newValue);
  if (newValue - 1 == -1) {
    departmentType0.value = "";
  } else {
    departmentType0.value = newValue - 1;
  }

  if (active.value == 0) {
    getcaseList();
  }
  if (active.value == 1) {
    getforumList();
  }
  if (active.value == 2) {
    getwenLists();
  }
  // localStorage.setItem("active", newValue);
});
watch(active, (newValue, oldValue) => {
  if (newValue == 0) {
    getcaseList();
  }
  if (newValue == 1) {
    getforumList();
  }
  if (newValue == 2) {
    getwenLists();
  }
  localStorage.setItem("active", newValue);
});
onMounted(() => {
  let avtBool = localStorage.getItem("active");
  if (avtBool == null || avtBool == 0) {
    active.value = 0;
  } else if (avtBool == 1) {
    active.value = 1;
  } else {
    active.value = 2;
  }
  getcaseList();
  // getforumList();
});

//案例列表
const getcaseList = () => {
  loadBool.value = true;
  let data = {
    pageNum: 1,
    pageSize: 100,
    isOpen: 0,
    departmentType: departmentType0.value,
  };
  caseList(data).then((res) => {
    if (res.status == 200) {
      caseData.value = res.data.rows;
      loadBool.value = false;
    }
  });
};
//贴吧列表
const getforumList = () => {
  loadBool.value = true;
  let data = {
    pageNum: 1,
    pageSize: 100,
    isOpen: 0,
    departmentType: departmentType0.value,
  };
  forumList(data).then((res) => {
    if (res.status == 200) {
      forumData.value = res.data.rows;
      loadBool.value = false;
    }
  });
};
//文献列表
const getwenLists = () => {
  loadBool.value = true;
  let data = {
    pageNum: 1,
    pageSize: 100,
    isOpen: 0,
    departmentType: departmentType0.value,
  };
  getwenList(data).then((res) => {
    wenData.value = res.data.rows;
    loadBool.value = false;
  });
};
//刷新
const onRefresh = () => {
  if (active.value == 0) {
    let data = {
      pageNum: 1,
      pageSize: 100,
      isOpen: 0,
      departmentType: departmentType0.value,
    };
    //
    caseList(data)
      .then((res) => {
        if (res.status == 200) {
          caseData.value = res.data.rows;
          showToast("刷新成功");
          loading.value = false;
        }
      })
      .catch((err) => {
        showToast("刷新失败");
        loading.value = false;
      });
  }
  if (active.value == 1) {
    let data = {
      pageNum: 1,
      pageSize: 100,
      isOpen: 0,
      departmentType: departmentType0.value,
    };
    forumList(data)
      .then((res) => {
        if (res.status == 200) {
          forumData.value = res.data.rows;
          showToast("刷新成功");
          loading.value = false;
        }
      })
      .catch((err) => {
        showToast("刷新失败");
        loading.value = false;
      });
  }
  if (active.value == 2) {
    let data = {
      pageNum: 1,
      pageSize: 100,
      isOpen: 0,
      departmentType: departmentType0.value,
    };
    getwenList(data)
      .then((res) => {
        if (res.status == 200) {
          wenData.value = res.data.rows;
          showToast("刷新成功");
          loading.value = false;
        }
      })
      .catch((err) => {
        showToast("刷新失败");
        loading.value = false;
      });
  }
};
const onClickRight = () => {
  let index: any = active.value;
  if (active.value == 2) {
    index = "wen";
  }
  router.push({
    path: "/ReleaseIndex",
    query: {
      value: index,
    },
  });
};
//滚动条触顶
const handleScroll = (event) => {
  const scrollTop = event.target.scrollTop;
  if (scrollTop == 0) {
    freshBool.value = false;
  } else {
    freshBool.value = true;
  }
};
//进入详情
const nextToMsg = (value: any, index: any) => {
  router.push({
    path: "/TiebMsg",
    query: {
      value: value,
      index: index,
    },
  });
};

// 收藏
let fabool = ref(true);
const FavoriteBtn = (value: any, type: any, index: any) => {
  window.event.cancelBubble = true; //取消事件的冒泡机制
  if (fabool.value) {
    if (type == 1) {
      if (caseData.value[index].collectFlag == "false") {
        let data = {
          businessId: value,
          businessType: type,
          operatorType: 3,
        };
        collLikeApi(data).then((res) => {
          console.log(res);
          getcaseList();
        });
      } else {
        let data = {
          businessId: value,
          businessType: type,
          operatorType: 3,
        };
        cancelCollLikeApi(data).then((res) => {
          console.log(res);
          getcaseList();
        });
      }
    }
    if (type == 2) {
      if (forumData.value[index].collectFlag == "false") {
        let data = {
          businessId: value,
          businessType: type,
          operatorType: 3,
        };
        collLikeApi(data).then((res) => {
          getforumList();
        });
      } else {
        let data = {
          businessId: value,
          businessType: type,
          operatorType: 3,
        };
        cancelCollLikeApi(data).then((res) => {
          getforumList();
        });
      }
    }
    if (type == 3) {
      if (wenData.value[index].collectFlag == "false") {
        let data = {
          businessId: value,
          businessType: type,
          operatorType: 3,
        };
        collLikeApi(data).then((res) => {
          getwenLists();
        });
      } else {
        let data = {
          businessId: value,
          businessType: type,
          operatorType: 3,
        };
        cancelCollLikeApi(data).then((res) => {
          getwenLists();
        });
      }
    }
    fabool.value = false;
    setTimeout(() => {
      fabool.value = true;
    }, 1000);
  }
};
// 点赞
let likebool = ref(true);
const LikeBtn = (value: any, type: any, index: any) => {
  window.event.cancelBubble = true; //取消事件的冒泡机制
  if (likebool.value) {
    if (type == 1) {
      if (caseData.value[index].likeFlag == "false") {
        let data = {
          businessId: value,
          businessType: type,
          operatorType: 2,
        };
        collLikeApi(data).then((res) => {
          console.log(res);
          getcaseList();
        });
      } else {
        let data = {
          businessId: value,
          businessType: type,
          operatorType: 2,
        };
        cancelCollLikeApi(data).then((res) => {
          console.log(res);
          getcaseList();
        });
      }
    }
    if (type == 2) {
      if (forumData.value[index].likeFlag == "false") {
        let data = {
          businessId: value,
          businessType: type,
          operatorType: 2,
        };
        collLikeApi(data).then((res) => {
          console.log(res);
          getforumList();
        });
      } else {
        let data = {
          businessId: value,
          businessType: type,
          operatorType: 2,
        };
        cancelCollLikeApi(data).then((res) => {
          console.log(res);
          getforumList();
        });
      }
    }
    if (type == 3) {
      if (wenData.value[index].likeFlag == "false") {
        let data = {
          businessId: value,
          businessType: type,
          operatorType: 2,
        };
        collLikeApi(data).then((res) => {
          getwenLists();
        });
      } else {
        let data = {
          businessId: value,
          businessType: type,
          operatorType: 2,
        };
        cancelCollLikeApi(data).then((res) => {
          getwenLists();
        });
      }
    }
    likebool.value = false;
    setTimeout(() => {
      likebool.value = true;
    }, 1000);
  }
};
</script>
<style scoped>
.msgbox {
  width: 96%;
  height: calc(100vh - 203px);
  overflow-y: scroll;
  margin: 5px auto 0 auto;
  padding: 5px;
}
::v-deep .van-card__content > div {
  padding-left: calc(100% - 45vw);
}
</style>
<style>
.van-button__text {
  padding-top: 2px;
}
.van-card__thumb {
  width: 100px !important;
  height: 100px !important;
}
.van-card__title {
  font-size: 12px !important;
}
.msgtext {
  padding-top: 6px;
  color: rgb(192, 196, 204);
  transform: scale(0.8);
  transform-origin: top left;
}
</style>